import classnames from 'classnames';
import React, { InputHTMLAttributes } from 'react';

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
	children: React.ReactNode;
	label: string;
	className?: string;
	placeholder?: string;
	name: string;
	handleChange: any;
}

const Select: React.FC<SelectProps> = ({
	children,
	label,
	className = '',
	placeholder,
	name,
	handleChange,
	...rest
}) => {
	return (
		<div
			className={classnames('form-row form-row--select', {
				className: !!className,
			})}>
			{label && (
				<label htmlFor={name} className="form-label">
					{label}
				</label>
			)}
			<select
				name={name}
				className="form-input"
				placeholder={placeholder}
				aria-label={label}
				data-nice-name={label}
				onChange={handleChange}
				{...rest}>
				{children}
			</select>
		</div>
	);
};

export default Select;
