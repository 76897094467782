// i18next-extract-mark-ns-start support-academia-licence-request

import { LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import UniversityForm from '@components/UniversityForm';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const AcademiaUniversityPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();
	const fluids = getImage(data.fluids.childImageSharp.gatsbyImageData);
	const mech = getImage(data.mech.childImageSharp.gatsbyImageData);
	const systems = getImage(data.systems.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection className="section__content" layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<p>
							As part of our commitment to supporting the science, technology, engineering and mathematics (STEM) students of today, to be
							the engineering innovation leaders of tomorrow, we are proud offer universities worldwide a range of cost-effective licensing
							options to use our simulation software for teaching and research purposes*.
							These include academic license packages for research groups and classroom settings ranging from 5 to 50+ seats for all products.
							A limited amount of technical support is available, with the scope evaluated on a case-by-case basis.
						</p>
						<p>
							Our available simulation software includes:
						</p>
					</Trans>

					{fluids && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={fluids}
								alt="Fluid Dynamics"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_2">
						<p>
							The <a href={LINKS.PRODUCTS_FLUID_DYNAMICS}>Fluid Dynamics</a> suite is a comprehensive range of tools for 1D and 3D CFD simulation of liquid and gas flow.
							Enabling optimisation, calibration and detailed combustion and
							thermal analysis to model ICE behaviour across a range of fuel types.
						</p>
					</Trans>
					{mech && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={mech}
								alt="Structural Mechanics"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_3">
						<p>
							The <a href={LINKS.PRODUCTS_STRUCTURAL_MECHANICS}>Structural Mechanics</a> suite for designing, analysing and optimising mechanical systems in terms of friction, durability and NVH.
							Our range of specialised tools provides detailed analyses of dynamic behaviour of engine and transmission components.
						</p>
					</Trans>

					{systems && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={systems}
								alt="Systems Engineering"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_4">
						<p>
							The <a href={LINKS.PRODUCTS_SYSTEMS_ENGINEERING}>Systems Engineering</a> toolset for analysing the behaviour of sub-systems and the impact of design choices on overall system performance.
							Our comprehensive automotive libraries provide in-depth modelling of vehicle sub-systems and their interactions.
						</p>

						<p>
							*If the university is using Realis Simulation software for any published academic articles, marketing or press releases,
							we request that the usage of Realis Simulation tools is acknowledged.
						</p>
						<hr />
						<p>
							To apply for an academic license then please as Central Contact please fill out the form and send an electronically completed copy of the MLA to
							{' '}<a href="mailto:universities@realis-simulation.com">universities@realis-simulation.com</a>{' '} for review by Realis Simulation.
						</p>
					</Trans>
					<Button to={LINKS.REALIS_MLA} type="download">
						<Trans i18nKey="download">Download MLA</Trans>
					</Button>
				</div>
				<Aside>
					<Aside.Widget>
						<UniversityForm name="university"
							title={t("License application")}
						/>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default AcademiaUniversityPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["support-academia-licence-request", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/academia/shutterstock_2102457295_1536x864.jpg" }) {
			...imageBreaker
		}
		fluids: file(relativePath: { eq: "content/FluidDynamics_notxt.png" }) {
			...imageBreaker
		}
		mech: file(relativePath: { eq: "content/StructuralMechanics_notxt.png" }) {
			...imageBreaker
		}
		systems: file(relativePath: { eq: "content/SystemsEngineering_notxt.png" }) {
			...imageBreaker
		}
	}
`;
