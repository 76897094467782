import classnames from 'classnames';
import React, { InputHTMLAttributes } from 'react';

interface TextareaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
	label: string;
	className?: string;
	placeholder?: string;
	name: string;
	handleChange: any;
}

const TextArea: React.FC<TextareaProps> = ({
	label,
	className = '',
	placeholder,
	name,
	handleChange,
	...rest
}) => {
	return (
		<div
			className={classnames('form-row form-row--input', {
				[className]: !!className,
			})}>
			<textarea
				name={name}
				id={name}
				cols={30}
				rows={10}
				aria-label={label}
				data-nice-name={label}
				placeholder={placeholder}
				className="form-input"
				onChange={handleChange}
				{...rest}></textarea>
			{label && (
				<label htmlFor={name} className="form-label">
					<span className="form-label-span">{label}</span>
				</label>
			)}
		</div>
	);
};

export default TextArea;
